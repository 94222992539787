
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.aws-btn {
  --organic-arrow-height: 2.4em !important;
  --organic-arrow-thickness: 2px !important;
}

body {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  background: #f0f0f0;
  color : #333;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--title-color);
  font-weight: bold;
}

a, a:visited {
  color: inherit;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

section.primary {
  position: relative;
  width: calc(100% - 67px );
  height: 100vh;
  background: url("../img/section-bg.jpg");
  background-size: cover;
  transition: width 0.2s;
}

section.primary.narrow {
  width: calc(100% - 67px - 270px );
}
section.primary.narrow.without-submenu {
  width: calc(100% - 67px );
}

button {
  color: var(--primary-color);
  background: transparent;
  border: 0;
}

button:focus {
  outline: none;
}

.main-view {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('') center no-repeat;
  background-size: cover;
}

.main-view.hide {
  display: none
}

.main-view img {
  width: 100%;
}

.default-button {
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 2em;
  padding: 1em 4em;
  text-transform: uppercase;
}

.default-button.lined {
  color: var(--primary-color);
  background: transparent;
  border:1px solid var(--primary-color);
}

.default-button.secondary {
  background-color: rgba(10, 31, 59, 0.57);
}
.btn-disabled {
  opacity: .3;
}
.button-back {
  position: absolute;
  width: 2.5em;
  height: 2.5em;
  border: 0;
  left: 2em;
  bottom: 2em;
  background-color: var(--primary-color);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-image: url('../img/icon-arrow-left.svg');
  mask-size: 2.5em;
}

.close-button {
  position: absolute;
  width: 2.3em;
  height: 2.3em;
  border: 0;
  top: 3em;
  right: 3em;
  z-index: 10;
}

.close-button > div {
  position: absolute;
  left: 0;
  top: 1.25em;
  width: 100%;
  height: 0.16em;
  background: #fff;
  transform: rotate(45deg);
  transform-origin: center;
  border-radius: 10px;
}
.close-button.dark > div {
  background: #222;
}
.close-button > div:last-child {
  transform: rotate(-45deg);
}

.close-button.colored > div {
  background: var(--primary-color);
}

.spinner-border {
  position: absolute;
  left: 50%;
  top: 50%;
  border-color: var(--primary-color);
  border-right-color: transparent;
}

.check-container {
  display: block;
  position: relative;
  padding-left: 1.6rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
}

.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 50%;
  border:1px solid #BCBCBC
}

.check-container:hover input ~ .checkmark,
.check-container input:checked ~ .checkmark{
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.check-container:hover input#status_available ~ .checkmark,
.check-container input#status_available:checked ~ .checkmark{
  border-color: var(--available-color);
  background-color: var(--available-color);
}

.check-container:hover input#status_sold ~ .checkmark,
.check-container input#status_sold:checked ~ .checkmark{
  border-color: var(--sold-color);
  background-color: var(--sold-color);
}

.check-container:hover input#status_reserved ~ .checkmark,
.check-container input#status_reserved:checked ~ .checkmark{
  border-color: var(--option-color);
  background-color: var(--option-color);
}

.checkmark:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.check-container input:hover ~ .checkmark:after,
.check-container input:checked ~ .checkmark:after {
  border: 2px solid white;
}
.red-logout-button{
  position:relative;
  display: block;
  width: 2.3rem;
  height: 2.3rem;
  border: 1px solid #d85757;
  border-radius: 50%;
  cursor: pointer;
}
.red-logout-button:before
{
  content : '';
  position: absolute;
  left:0.6rem;
  top:0.5rem;
  width: 1.2rem;
  height: 1.2rem;
  background: url(../img/icon-red-logout.svg) no-repeat center;
}
.red-logout-button:hover
{
  background: #d85757;
}
.red-logout-button:hover:before
{
  background: url(../img/icon-red-logout-light.svg) no-repeat center;
}
.client-menu .red-logout-button
{
  transform: scale(0.8);
}

@media (min-width: 900px) {

  body.main-menu-is-open section.primary:not(.brochure) {
    width: calc(100% - 30em);
  }

}

@media (min-width: 1440px) {

 body {
   font-size: 12px;
 }

}

@media (min-width: 1680px) {

  body {
    font-size: 12px;
  }
 
 }

/* Sign Section */

.sign-section {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}
.sign-wrap {
  position: relative;
  width: 25em;
  min-height: 100vh;
  padding: 1em 0 0 ;
}

.sign-wrap .inner {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 2em;
}
.sign-wrap .form-element{
  margin: 1.5em 0;
}

.sign-wrap .form-control {
  border: 0;
  border-bottom: 1px solid var(--primary-border-color);
  border-radius: 0;
  padding-left: 0;
}

.sign-wrap .form-control:nth-child(3) {
  margin-bottom: 3em;
}
.seperator-text {
  position: relative;
  text-align: center;
}
.seperator-text:before,.seperator-text:after {
  content : "";
  position: absolute;
  height: 1px;
  width: 40%;
  background: #999;
}
.seperator-text:before {
  top:10px;
  left:0;
}
.seperator-text:after {
  top:10px;
  right:0;
}
.sign-wrap .logo {
  text-align: center;
  margin-bottom:1.5rem
}
.sign-wrap .logo img {
  width:240px;
}
.sign-wrap h2 {
  font-size:1.7rem;
  text-align: center;
}
.sign-wrap .button-container,.sign-wrap a {
  display: block;
  text-align: center;
}
.sign-wrap button {
  display: block;
  width: 100%;
}
.sign-section .sales-login-link {
  position: absolute;
  right:2rem;
  bottom:2rem
}

#exit-filter-mode
{

  position: fixed;
  left: 2rem;
  bottom: 2rem;
  transition: all ease 1s;
  padding: 0.5em 1.5em;
  z-index: 99;
}
.black-wizio-btn {
  position: fixed;
  display: block;
  padding: 10px;
  bottom: 0.4rem;
  left : 50%;
  transform: translateX(-50%);
  z-index: 1;
  font-size:11px;
  font-weight: bold;
  text-align: center;
}