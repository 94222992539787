.splash-screen
{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 999;
    transition: opacity 1s, visibility 1s;
}

.splash-screen-container img
{
    display: block;
}
.hide-splash
{
    opacity: 0;
    visibility: hidden;

}
video {
    position:absolute;
    width: 100vw;
    height: 100vh;
    z-index: 3;

}

